@media only screen and (max-width: 560px) {
    .only-desktop {
        display: none !important;
    }   
}

@media only screen and (min-width: 560px) {
    .only-mobile {
        display: none !important;
    }   
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #fffc;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 6px;
}

a {
    color: #fffc;
    font-weight: bold;
}

a:visited {
    color: #88f;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #0f0000;
}

.container-drawer {
    display: flex;
    flex-direction: column;
    margin: 0px 0 6px 0;
}

#controller {
    position: absolute;
    width: 300px;
    padding: 16px;
    margin: 24px;
    z-index: 2;
    overflow-y: auto;
    max-height: calc(100% - 24px - 24px);
    box-sizing: border-box;
}

@media only screen and (max-width: 560px) {
    #controller {
        margin: 0;
        width: 100%;
        max-height: 100%;
    }
}

.button {
    margin: 0 8px 0 8px;
    user-select: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.checkbox {
    width: 12px;
    height: 12px;
    border: 1px solid #000;
    border-radius: 3px;
    margin: 0 2px 0 4px;
    transition: all 0.3s ease;
}

.break {
    height: 24px;
    width: 2px;
    background: #fff;
    opacity: 10%;
    margin: 0 12px 0 4px;
}

.break-h {
    height: 2px;
    width: 100%;
    background: #fff;
    opacity: 10%;
    margin: 12px 0;
}

.mapboxgl-ctrl, .mapboxgl-ctrl-attrib a {
    color: #fffc !important;
}

.mapboxgl-ctrl-group,
.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl.mapboxgl-ctrl-scale {
    backdrop-filter: blur(24px) !important;
    background: #0006 !important;
    border-radius: 6px !important;
    box-shadow: 0 10px 20px -3px #00000026 !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    border-radius: 6px 0 0 0 !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-scale {
    border-radius: 0 0 6px 6px !important;
    border-left: 2px solid #fff8 !important;
    border-right: 2px solid #fff8 !important;
    border-bottom: 2px solid #fff8 !important;
}

.mapboxgl-ctrl-group button + button {
    border-top: 1px solid #fff8 !important;
}

.mapboxgl-ctrl-icon {
    filter: brightness(3);
}

[type="checkbox"], [type="range"] {
    vertical-align:middle;
}

input[type="range"] {
    width: 120px;
}

label {
    font-weight: bold;
}

span.description {
    display: block;
    font-size: 10px;
    font-weight: normal;
    margin-left: 4px;
}

label span.description{
    margin-left: 24px;
}

.collapsible-toggle {
    position: relative;
    padding: 6px 0;
    cursor: pointer;
    user-select: none;
}

.collapsible-toggle:hover {
    /* background-color: #0002; */
}

.collapsible-toggle::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    right: 0;
    height: 24px;
    width: 24px;
    opacity: 0.8;
    background-image: url(../icons/expand_more_white_24dp.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 24px 24px;
    transition: transform 200ms ease-out;
    transform: rotate(180deg);
}

.collapsible-toggle h3 {
    margin: 0 0;
}

.collapsible-toggle.collapsed::after {
    transform: rotate(0deg);
}

.collapsible-content {
    overflow: hidden;
    transition: max-height 200ms ease-out;
    max-height: 400px;
}

.collapsible-content.collapsed {
    overflow: hidden;
    max-height: 0;
}

#close-button {
    content: "";
    position: absolute;
    bottom: 0;
    right: 8px;
    height: 24px;
    width: 24px;
    opacity: 0.8;
    background-image: url(../icons/close_white_24dp.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 24px 24px;
    margin: 8px;
    cursor: pointer;
}

#options-button .mapboxgl-ctrl-icon {
    background-image: url(../icons/settings_white_24dp.svg);
    opacity: 0.6;
}

.github-link {
    position: relative;
    padding-left: 20px;
    /* font-weight: bold; */
}

.github-link::before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 16px;
    height: 16px;
    background-image: url('../icons/GitHub-Mark-Light-32px.png');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    opacity: 0.8;
}

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-thumb {
    background: #fff3;
    border-radius: 3px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #fff4;
}


*::-webkit-scrollbar-thumb:window-inactive {
    background: #fff1;
}
